import React, { useEffect } from "react";
import { Option, Select, Text } from "@appsmith/ads";
import {
  createMessage,
  GENERATE_APPROVAL_PAGE_ERROR_NO_QUERY,
  GENERATE_APPROVAL_PAGE_FORM_SUB_TITLE_FROM_WORKFLOW,
  GENERATE_APPROVAL_PAGE_SELECT_APP_LABEL,
} from "ee/constants/messages";
import { useDispatch, useSelector } from "react-redux";
import type { AppState } from "ee/reducers";
import { getActionByPluginPackageName } from "ee/selectors/entitiesSelector";
import {
  getApplicationsOfWorkspace,
  getIsFetchingApplications,
} from "ee/selectors/selectedWorkspaceSelectors";
import { SelectWrapper } from "pages/Editor/GeneratePage/components/GeneratePageForm/styles";
import { DROPDOWN_DIMENSION } from "pages/Editor/GeneratePage/components/constants";
import { fetchAllApplicationsOfWorkspace } from "ee/actions/applicationActions";
import { PluginPackageName } from "entities/Plugin";
import { getCurrentWorkflowId } from "ee/selectors/workflowSelectors";

interface Props {
  selectedAppId: string;
  selectedWorkflowId: string;
  setSelectedWorkflowId: (id: string) => void;
  setSelectedAppId: (id: string) => void;
}

export default function GeneratePageFromWorkflowsForm({
  selectedAppId,
  setSelectedAppId,
  setSelectedWorkflowId,
}: Props) {
  const dispatch = useDispatch();
  const currentWorkflowId = useSelector(getCurrentWorkflowId);

  useEffect(
    function setWorkflowIdForCrudForm() {
      if (currentWorkflowId) {
        setSelectedWorkflowId(currentWorkflowId);
      }
    },
    [currentWorkflowId, setSelectedWorkflowId],
  );

  // All queries from the workflow plugin
  const allQueries = useSelector((state: AppState) =>
    getActionByPluginPackageName(state, PluginPackageName.WORKFLOW),
  );

  // all applications in the workspace, used to select the target application
  const allApps = useSelector(getApplicationsOfWorkspace);
  const fetchingApps = useSelector(getIsFetchingApplications);

  useEffect(
    function fetchWorkspaceTrigger() {
      dispatch(fetchAllApplicationsOfWorkspace());
    },
    [dispatch],
  );

  useEffect(
    function setDefaultAppId() {
      if (allApps.length > 0 && allQueries.length > 0) {
        setSelectedAppId(allApps[0].id);
      } else {
        setSelectedAppId("");
      }
    },
    [allApps, allQueries, setSelectedAppId],
  );

  if (allQueries.length === 0) {
    return (
      <div className="flex flex-col">
        <Text kind="action-l">
          {createMessage(GENERATE_APPROVAL_PAGE_ERROR_NO_QUERY)}
        </Text>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <Text kind="heading-s">
        {createMessage(GENERATE_APPROVAL_PAGE_FORM_SUB_TITLE_FROM_WORKFLOW)}
      </Text>
      <SelectWrapper width={DROPDOWN_DIMENSION.WIDTH}>
        <Text kind="body-m">
          {createMessage(GENERATE_APPROVAL_PAGE_SELECT_APP_LABEL)}
        </Text>
        <Select
          data-testid="t--generate-approval-page-workflow-select"
          isDisabled={allApps.length === 0}
          isLoading={fetchingApps}
          onChange={setSelectedAppId}
          value={selectedAppId}
        >
          {allApps.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </SelectWrapper>
    </div>
  );
}
