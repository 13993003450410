import { Icon, Option, Select, Spinner, Tooltip } from "@appsmith/ads";
import {
  changeConsumedPackageVersionInit,
  fetchRemotePackageVersionsInit,
} from "ee/actions/packageActions";
import type { Package } from "ee/constants/PackageConstants";
import TruncatedTextWithTooltip from "ee/pages/common/TruncatedTextWithTooltip";
import React, { useEffect, useRef, useState } from "react";
import { useCallback, useMemo, type ReactNode } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Flex } from "@appsmith/ads";

const RIGHT_WIDTH = 100;

const StyledSelect = styled(Select)`
  .rc-select-selector {
    min-width: unset;
  }
`;

interface PackageLibItemProps extends Package {
  isLoading: boolean;
}

function PackageLibItem({
  activeVersion,
  gitArtifactMetadata,
  id,
  isLoading,
  name,
  packageUUID,
  versions,
}: PackageLibItemProps) {
  const [showSuccess, setShowSuccess] = useState(false);
  const successTimer = useRef<number | null>(null);
  const isGitConnected = !!gitArtifactMetadata;

  const isLoadingOld = useRef(isLoading);
  const dispatch = useDispatch();

  const versionOptions = useMemo(() => {
    const options: ReactNode[] = [];

    if (activeVersion) {
      options.push(
        <Option key={activeVersion} value={activeVersion}>
          {activeVersion === "latest" ? "Latest" : activeVersion}
        </Option>,
      );
    }

    if (versions && Array.isArray(versions)) {
      versions.forEach((version) => {
        if (version !== activeVersion) {
          options.push(
            <Option key={version} value={version}>
              {version === "latest" ? "Latest" : version}
            </Option>,
          );
        }
      });
    }

    return options;
  }, [activeVersion, versions]);

  useEffect(
    function onLoadingChangeEffect() {
      if (isLoading !== isLoadingOld.current) {
        if (!isLoading && isLoadingOld.current) {
          setShowSuccess(true);
        }

        isLoadingOld.current = isLoading;
      }
    },
    [isLoading],
  );

  useEffect(
    function onSetSuccessEffect() {
      if (showSuccess) {
        successTimer.current = setTimeout(() => {
          setShowSuccess(false);
        }, 2000);
      }

      return () => {
        if (successTimer.current) {
          clearTimeout(successTimer.current);
        }
      };
    },
    [showSuccess],
  );

  const handleDropdownVisibleChange = useCallback(
    (open: boolean) => {
      if (open && isGitConnected) {
        dispatch(fetchRemotePackageVersionsInit(id));
      }
    },
    [dispatch, id, isGitConnected],
  );

  const handleSelect = useCallback(
    (updatedVersion: string) => {
      const payload = {
        packageUUID,
        version: updatedVersion,
      };

      dispatch(changeConsumedPackageVersionInit(payload));
    },
    [dispatch, packageUUID],
  );

  return (
    <Flex
      alignItems="center"
      data-testid="t--package-lib-item"
      gap="spaces-3"
      justifyContent="space-between"
    >
      <Flex flex={1}>
        <TruncatedTextWithTooltip>{name}</TruncatedTextWithTooltip>
      </Flex>
      {isLoading && (
        <Tooltip content="Changing package version...">
          <Spinner data-testid="t--package-version-loading" size="md" />
        </Tooltip>
      )}
      {showSuccess && (
        <Tooltip content="Package version changed">
          <Icon
            color="var(--ads-v2-color-green-600)"
            name="oval-check"
            size="md"
          />
        </Tooltip>
      )}
      <Flex width={`${RIGHT_WIDTH}px`}>
        <StyledSelect
          data-testid="t--package-version-selector"
          isDisabled={isLoading}
          onChange={handleSelect}
          onDropdownVisibleChange={handleDropdownVisibleChange}
          placeholder="Select Version"
          size="sm"
          value={activeVersion}
        >
          {versionOptions}
        </StyledSelect>
      </Flex>
    </Flex>
  );
}

export default PackageLibItem;
