import { Flex, Icon, Text } from "@appsmith/ads";
import {
  createMessage,
  EMPTY_PACKAGE_CONTROL_DESCRIPTION,
  EMPTY_PACKAGE_CONTROL_TITLE,
} from "ee/constants/messages";
import PackageLearnLink from "ee/pages/common/PackageLearnLink";
import React from "react";
import styled from "styled-components";

const StyledBox = styled(Flex)`
  background: var(--ads-color-background-secondary);
  width: var(--ads-spaces-16);
  height: var(--ads-spaces-16);
`;

const StyledEmptyContainer = styled(Flex)`
  padding: var(--ads-spaces-5) var(--ads-spaces-7);
  padding-top: var(--ads-spaces-15);
`;

const CenteredTextFlex = styled(Flex)`
  text-align: center;
`;

function PackageLibEmpty() {
  return (
    <StyledEmptyContainer
      alignItems="center"
      data-testid="t--package-lib-empty"
      flexDirection="column"
      gap="spaces-3"
      justifyContent="center"
    >
      <StyledBox justifyContent="center">
        <Icon name="packages-v3" size="lg" />
      </StyledBox>
      <CenteredTextFlex flexDirection="column" gap="spaces-2">
        <Text kind="heading-xs">
          {createMessage(EMPTY_PACKAGE_CONTROL_TITLE)}
        </Text>
        <Text>{createMessage(EMPTY_PACKAGE_CONTROL_DESCRIPTION)}</Text>
      </CenteredTextFlex>
      <PackageLearnLink kind="secondary" />
    </StyledEmptyContainer>
  );
}

export default PackageLibEmpty;
