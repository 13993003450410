import { Flex, Text } from "@appsmith/ads";
import { createMessage, PACKAGES_CONTROL_TITLE } from "ee/constants/messages";
import PaneHeader from "IDE/Components/PaneHeader";
import React, { useEffect, useMemo } from "react";

import { objectKeys } from "@appsmith/utils";
import styled from "styled-components";
import { sortBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getPackages,
  selectChangeConsumedPackageVersionLoading,
} from "ee/selectors/packageSelectors";
import type { Package } from "ee/constants/PackageConstants";
import PackageLibItem from "./PackageLibItem";
import PackageLibEmpty from "./PackageLibEmpty";
import { fetchConsumablePackagesInWorkspace } from "ee/actions/packageActions";
import { getCurrentApplication } from "ee/selectors/applicationSelectors";

const PANE_HEADER_HEIGHT = 40;
const VERSION_TITLE_HEADER_HEIGHT = 30;
const RIGHT_WIDTH = 100;

const StyledBody = styled(Flex)`
  padding: var(--ads-spaces-4);
  padding-top: var(--ads-spaces-5);
  height: 100%;
`;

const StyledVersionBody = styled(Flex)`
  height: calc(
    100% - ${PANE_HEADER_HEIGHT}px - ${VERSION_TITLE_HEADER_HEIGHT}px - 30px
  );
  overflow-y: auto;
`;

function PackageLibSection() {
  const dispatch = useDispatch();
  const changeConsumedPackageVersionLoading = useSelector(
    selectChangeConsumedPackageVersionLoading,
  );
  const packages = useSelector(getPackages);
  const consumedPackages = useMemo(() => {
    const map: Record<string, Package> = {};

    Object.values(packages).forEach((pkg) => {
      if (pkg?.activeVersion) {
        map[pkg.id] = pkg;
      }
    });

    return map;
  }, [packages]);

  const hasConsumedPackages = objectKeys(consumedPackages).length > 0;

  const application = useSelector(getCurrentApplication);

  useEffect(
    function fetchConsumablePackagesOnInitEffect() {
      if (application) {
        dispatch(
          fetchConsumablePackagesInWorkspace({
            workspaceId: application.workspaceId,
            applicationId: application.id,
          }),
        );
      }
    },
    [application, dispatch],
  );

  if (!hasConsumedPackages) return <PackageLibEmpty />;

  return (
    <>
      <PaneHeader title={createMessage(PACKAGES_CONTROL_TITLE)} />
      <StyledBody flexDirection="column" gap="spaces-2">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex flex={1}>
            <Text color="var(--ads-v2-color-gray-500)" kind="body-s">
              Package
            </Text>
          </Flex>
          <Flex width={`${RIGHT_WIDTH}px`}>
            <Text color="var(--ads-v2-color-gray-500)" kind="body-s">
              Version
            </Text>
          </Flex>
        </Flex>
        <StyledVersionBody flexDirection="column" gap="spaces-3">
          {sortBy(Object.values(consumedPackages), ["name"]).map((pkg) => {
            const isChangeVersionLoading =
              changeConsumedPackageVersionLoading === pkg.packageUUID;

            return (
              <PackageLibItem
                isLoading={isChangeVersionLoading}
                key={pkg.baseId}
                {...pkg}
              />
            );
          })}
        </StyledVersionBody>
      </StyledBody>
    </>
  );
}

export default PackageLibSection;
