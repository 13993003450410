import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@appsmith/ads";
import {
  createMessage,
  GENERATE_APPROVAL_PAGE_FORM_TITLE,
  GENERATE_APPROVAL_PAGE_MODAL_CTA_DISMISS,
  GENERATE_NEW_PAGE_BUTTON_TEXT,
} from "ee/constants/messages";
import { useSelector, useDispatch } from "react-redux";
import {
  closeGenerateApprovalPageModal,
  generateApprovalPageInit,
  openGenerateApprovalPageModal,
} from "./store/generateWorkflowApprovalPageActions";
import {
  getIsGenerateApprovalPageModalLoading,
  getIsGenerateApprovalPageModalOpen,
} from "./store/generateWorkflowApprovalPageSelector";
import GeneratePageFromAppForm from "./components/GeneratePageFromAppForm";
import GeneratePageFromWorkflowsForm from "./components/GeneratePageFromWorkflowsForm";
import type { IDEType } from "ee/IDE/Interfaces/IDETypes";
import { IDE_TYPE } from "ce/IDE/Interfaces/IDETypes";

const modalStyles = {
  width: "500px",
};

export function GenerateWorkflowApprovalPage({
  ideType,
}: {
  ideType: IDEType;
}) {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsGenerateApprovalPageModalOpen);
  const isLoading = useSelector(getIsGenerateApprovalPageModalLoading);
  const [isCTADisabled, setIsCTADisabled] = useState(true);
  const [selectedAppId, setSelectedAppId] = useState("");
  const [selectedWorkflowId, setSelectedWorkflowId] = useState("");

  useEffect(
    function disableStateWatcher() {
      if (selectedAppId.length === 0 || selectedWorkflowId.length === 0) {
        setIsCTADisabled(true);
      } else {
        setIsCTADisabled(false);
      }
    },
    [selectedAppId.length, selectedWorkflowId.length, setIsCTADisabled],
  );

  const onDismiss = useCallback(
    function onDismiss() {
      dispatch(closeGenerateApprovalPageModal());
      setSelectedAppId("");
      setSelectedWorkflowId("");
    },
    [dispatch],
  );

  function onSubmit() {
    dispatch(
      generateApprovalPageInit({
        applicationId: selectedAppId,
        sourceIDEType: ideType,
        workflowId: selectedWorkflowId,
      }),
    );
  }

  const handleModalOpenChange = useCallback(
    (modalState: boolean) => {
      if (modalState) {
        dispatch(openGenerateApprovalPageModal());
      } else {
        onDismiss();
      }
    },
    [dispatch, onDismiss],
  );

  return (
    <Modal onOpenChange={handleModalOpenChange} open={isOpen}>
      <ModalContent style={modalStyles}>
        <ModalHeader>
          {createMessage(GENERATE_APPROVAL_PAGE_FORM_TITLE)}
        </ModalHeader>
        <ModalBody>
          {ideType === IDE_TYPE.App ? (
            <GeneratePageFromAppForm
              selectedAppId={selectedAppId}
              selectedWorkflowId={selectedWorkflowId}
              setSelectedAppId={setSelectedAppId}
              setSelectedWorkflowId={setSelectedWorkflowId}
            />
          ) : (
            <GeneratePageFromWorkflowsForm
              selectedAppId={selectedAppId}
              selectedWorkflowId={selectedWorkflowId}
              setSelectedAppId={setSelectedAppId}
              setSelectedWorkflowId={setSelectedWorkflowId}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <div className="flex flex-row gap-x-2 mt-2">
            <Button
              className="ml-auto"
              data-testid={"t--hitl-crud-modal-cta-dismiss"}
              isDisabled={isLoading}
              isLoading={isLoading}
              kind="secondary"
              onClick={onDismiss}
              size="md"
            >
              {createMessage(GENERATE_APPROVAL_PAGE_MODAL_CTA_DISMISS)}
            </Button>
            <Button
              data-testid={"t--hitl-crud-modal-cta-submit"}
              isDisabled={isCTADisabled || isLoading}
              isLoading={isLoading}
              kind="primary"
              onClick={onSubmit}
              size="md"
            >
              {createMessage(GENERATE_NEW_PAGE_BUTTON_TEXT)}
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
