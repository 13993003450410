import React, { useEffect } from "react";
import { Select, Text } from "@appsmith/ads";
import {
  createMessage,
  GENERATE_APPROVAL_PAGE_ERROR_NO_WORKFLOW,
  GENERATE_APPROVAL_PAGE_FORM_SUB_TITLE_FROM_APP,
  GENERATE_APPROVAL_PAGE_SELECT_WORKFLOW_LABEL,
} from "ee/constants/messages";
import { getCurrentApplicationId } from "selectors/editorSelectors";
import { useDispatch, useSelector } from "react-redux";
import { SelectWrapper } from "pages/Editor/GeneratePage/components/GeneratePageForm/styles";
import { DROPDOWN_DIMENSION } from "pages/Editor/GeneratePage/components/constants";
import {
  getIsFetchingWorkflowsForApprovalPageGenerationModal,
  getWorkflowsForApprovalPageGenerationModal,
} from "../store/generateWorkflowApprovalPageSelector";
import { fetchWorkflowsWithApprovalRequest } from "ee/actions/workflowActions";
import type { Workflow } from "ee/constants/WorkflowConstants";

interface Props {
  selectedAppId: string;
  selectedWorkflowId: string;
  setSelectedWorkflowId: (id: string) => void;
  setSelectedAppId: (id: string) => void;
}

export default function GeneratePageFromAppForm({
  selectedWorkflowId,
  setSelectedAppId,
  setSelectedWorkflowId,
}: Props) {
  const dispatch = useDispatch();
  const currentApplicationId = useSelector(getCurrentApplicationId);
  const isFetchingWorkflows = useSelector(
    getIsFetchingWorkflowsForApprovalPageGenerationModal,
  );

  const workflows: Array<Workflow> = useSelector(
    getWorkflowsForApprovalPageGenerationModal,
  );

  useEffect(
    function setAppIdForCrudForm() {
      if (currentApplicationId) {
        setSelectedAppId(currentApplicationId);
      }
    },
    [currentApplicationId, setSelectedAppId],
  );

  useEffect(
    function fetchWorkflowsTrigger() {
      dispatch(fetchWorkflowsWithApprovalRequest());
    },
    [dispatch],
  );

  // set the first workflow as default ID
  useEffect(
    function setDefaultWorkflowId() {
      if (workflows && workflows.length > 0) {
        setSelectedWorkflowId(workflows[0].id);
      }
    },
    [workflows, setSelectedWorkflowId],
  );

  // once fetching is done, show the error if no workflows are found
  if (!isFetchingWorkflows && (!workflows || workflows.length === 0)) {
    return (
      <div className="flex flex-col">
        <Text kind="action-l">
          {createMessage(GENERATE_APPROVAL_PAGE_ERROR_NO_WORKFLOW)}
        </Text>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <Text kind="heading-s">
        {createMessage(GENERATE_APPROVAL_PAGE_FORM_SUB_TITLE_FROM_APP)}
      </Text>
      <SelectWrapper width={DROPDOWN_DIMENSION.WIDTH}>
        <Text kind="body-m">
          {createMessage(GENERATE_APPROVAL_PAGE_SELECT_WORKFLOW_LABEL)}
        </Text>
        <Select
          data-testid="t--generate-page-from-app-form-select"
          isDisabled={isFetchingWorkflows || currentApplicationId.length === 0}
          isLoading={isFetchingWorkflows}
          onChange={setSelectedWorkflowId}
          options={workflows.map((workflow) => ({
            label: workflow.name,
            value: workflow.id,
          }))}
          value={selectedWorkflowId}
        />
      </SelectWrapper>
    </div>
  );
}
