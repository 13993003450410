import type { AppState } from "ee/reducers";

export const getIsGenerateApprovalPageModalOpen = (state: AppState) =>
  state.ui.workflowPlugin.generateApprovalPageModalState.isOpen;

export const getIsGenerateApprovalPageModalLoading = (state: AppState) =>
  state.ui.workflowPlugin.generateApprovalPageModalState.isLoading;

export const getWorkflowsForApprovalPageGenerationModal = (state: AppState) =>
  state.ui.workflowPlugin.generateApprovalPageModalState.workflows;

export const getIsFetchingWorkflowsForApprovalPageGenerationModal = (
  state: AppState,
) => state.ui.workflowPlugin.generateApprovalPageModalState.isFetchingWorkflows;
